import initScrollReveal from "./scripts/scrollReveal";
import initTiltEffect from "./scripts/tiltAnimation";
import { targetElements, defaultProps } from "./data/scrollRevealConfig";

renderDataFromExcel();
function renderDataFromExcel() {
  function renderProjects(heading="", description="", url="") {
    return `
    <div class="row">
      <div class="col-lg-4 col-sm-12">
        <div class="project-wrapper__text load-hidden">
          <h3 class="project-wrapper__text-title">${heading}</h3>
          <div>
            <p class="mb-4">
            ${description}
            </p>
          </div>
          <a class="cta-btn cta-btn--hero" href="tel:+918377831803">
            Paint me a wall
          </a>
        </div>
      </div>
      <div class="col-lg-8 col-sm-12">
        <div class="project-wrapper__image load-hidden">
          <div>
            <div data-tilt data-tilt-max="4" data-tilt-glare="true" data-tilt-max-glare="0.5"
              class="thumbnail rounded js-tilt">
              <img alt="Project Image" class="img-fluid" src="${url}" />
            </div>
          </div>
        </div>
      </div>
    </div>
    `;
  }

  let sheetID = '1Tcto6OkQLTv_mwl7H-OlDvPknnf1dT_Ei3t9M7MdC-A';
    let title = 'mayank_projects';
    let range = 'A1:C20';
    
    let full = (`https://docs.google.com/spreadsheets/d/${sheetID}/gviz/tq?sheet=${title}&range=${range}`);
    
    let finalHTML = '';

    let renderProjectElement = document.getElementById('js_render_projects');

    fetch(full)
      .then(res => res.text())
      .then(rep => {
        let data = JSON.parse(rep.substr(47).slice(0, -2));
        let tableRows = data.table.rows;
        let heading, description, url;
        for(let row in Object.keys(tableRows)) {
          if(row == 0) {
            continue;
          }

          let singleRowArray = tableRows[row].c;
          
          if(singleRowArray[0] != null && typeof singleRowArray[0].v != "undefined") {
            heading = singleRowArray[0].v;
          }
          if(singleRowArray[1] != null && typeof singleRowArray[1].v != "undefined") {
            description = singleRowArray[1].v;
          }
          if(singleRowArray[2] != null && typeof singleRowArray[2].v != "undefined") {
            url = singleRowArray[2].v;
          }

          finalHTML += renderProjects(heading, description, url);
        }

        renderProjectElement.innerHTML = finalHTML

        initScrollReveal(targetElements, defaultProps);
        initTiltEffect();
      });
}